import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = `${API_URL}/checklist`;

export interface Checklist {
    project_id :string
    project_title : string,
    pending: [];
}
export const getChecklist = (): Promise<Checklist[]> => {
    return new Promise((resolve,reject)=>{
        axios.get(`${BASE_URL}`).then(({data})=>{
            resolve(data);
        }).catch((error)=>{
            reject(error);
        })
    })
}
