/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../../../../app/modules/auth";
import {toAbsoluteUrl} from "../../../helpers";
import { Checklist } from "../../../../app/modules/checklist/utils";

type checklistProps = {
    checklist: undefined | Checklist[];
}

const HeaderChecklistMenu  = ({checklist}: checklistProps) => {
    console.log(typeof(checklist));
    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary p-4 w-auto"
            data-kt-menu="true"
        >
        <div id="checklist" className="scroll-y min-h-250px mh-300px min-w-300px">
            <div className="fw-bold">
                Checklist
            </div>
            <div>
            <span className="text-muted">Kindly check the pending items below</span>
            <div className="mh-375px scroll-y me-n7 pe-7 accordion accordion-icon-collapse" id="bs_accordion">           
                {checklist && Object.values(checklist).map((checklistItem)=>(
                    <div className="ms-5">
                        <div className="fs-bold">{checklistItem.project_title} <span className="ms-2 text-danger fs-4 fw-bolder">!</span></div>
                        <div>
                            {checklistItem.pending.map((item)=>(
                                <div>
                                    <Link to={"/projects/"+checklistItem.project_id+"/"+item}>
                                    {item + ' ' + ' Pending'}
                                    </Link>
                                </div>                                  
                            ))}
                        </div>
                        <div className="border-bottom border-gray-300 border-bottom-dashed mt-2"></div>
                    </div>
                ))}
            </div>
            </div>
        </div>
        </div>
    );
};

export {HeaderChecklistMenu};
